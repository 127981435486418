import React, { Component, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import "./ChiSiamoStruttura.scss";
import IntroChiSiamo from "components/intro-chi-siamo/IntroChiSiamo";
import { ReactComponent as Water } from "icons/drop-bold.svg";
import { ReactComponent as Leaf } from "icons/leaf-bold.svg";
import { ReactComponent as Thunder } from "icons/lightning-bold.svg";
import { ReactComponent as Settings } from "icons/faders-bold.svg";
import { ReactComponent as Bag } from "icons/tote-bold.svg";
import { ReactComponent as Steam } from "icons/shield-chevron-bold.svg";
import { ReactComponent as Chat } from "icons/chats-circle-bold.svg";
import { ReactComponent as Target } from "icons/target-bold.svg";
import Placeholders from "components/placeholders/Placeholders";
import { Link } from "react-router-dom";

const Struttura = ({struttura,className})=> {
    const [active,setActive] = useState(false);
    const style = {paddingBottom:0,backgroundColor:active ? 'white' :'transparent'};
    if( className.indexOf('--last') !== -1 ) {
        style.gridColumnStart = 2;
        style.gridColumnEnd = 3;
    }
    return (
        <div className={className} style={style}>
        <a href="#" onClick={e => {e.preventDefault();setActive(!active)}}>
        <div className="heading" style={{marginBottom:0, backgroundColor: className.indexOf('--last') !== -1 ? 'rgb(136, 136, 136)' : null}}>
          {
            struttura.values.find(
              (st) => st.code === "SETTORE"
            ).value
          }
        </div>
      </a>
      { struttura?.values.find(
            (st) => st.code === "STAFF"
          ) && <>{active && <p
        style={{padding: '3rem 2rem',backgroundColor:'white'}}
        className="staff"
        dangerouslySetInnerHTML={{
          __html: struttura?.values.find(
            (st) => st.code === "STAFF"
          ).value,
        }}
      />}</> }
      { !struttura?.values.find(
            (st) => st.code === "STAFF"
          ) && <>{active && <div
        style={{padding: '3rem 2rem',backgroundColor:'white'}}
        className="staff"
        
      ><h2>
                      {
                        struttura?.values.find(
                          (st) => st.code === "NOME"
                        ).value
                      }
                    </h2>
                    <h3>
                      {
                        struttura?.values.find(
                          (st) => st.code === "CARICA"
                        ).value
                      }
                    </h3></div>}</> }

        </div>
    )
}

export default class ChiSiamoNostraStruttura extends Component {
  state = {
    struttura: [],
    loading: true,
    error: false,
  };

  async componentDidMount() {
    try {
      let struttura = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/List",
        {
          documentClasses: ["STRUTTURA"],
          heading: "\\CHI_SIAMO",
          folder: "STRUTTURA",
          documentValueOrders: [
            {
              code: "LIVELLO",
              type: "3",
              direction: "asc",
            },
            {
              code: "POSIZIONE",
              type: "3",
              direction: "asc",
            },
          ],
        }
      );

      struttura = struttura?.data?.data?.documents;

      struttura = struttura?.map(async (element) => {
        if (element?.values?.find((value) => value.code === "FOTO")) {
          let foto = await axios.post(
            "https://api.utilitalia.it/DMS/Files/File",

            {
              fileId: element?.values?.find((value) => value.code === "FOTO")
                ?.value?.id,
            }
          );
          foto = "data:image/jpeg;base64," + foto?.data?.data?.data;
          return { ...element, foto };
        } else {
          return { ...element };
        }
      });
      struttura = await Promise.all(struttura);
      this.setState({
        struttura: struttura,
      });
    } catch (err) {
      console.log("Error CDM ChiSiamoStruttura", err);
    }
    this.setState({ loading: false });
  }

  render() {
    const path = window.location.href;
    const onlyGeneralDirection = path.includes("direzione-generale");
    //console.log("STRUTTURA", this.state.struttura);
    return (
      <div id="chi-siamo-struttura">
        <Helmet>
          <title>Utilitalia - La Nostra Struttura</title>
          <meta
            name="description"
            content="Visita il sito e scopri tutti i membri che fanno parte del team di Utilitalia."
          />
        </Helmet>
        <IntroChiSiamo section="Struttura" />
        <div className="container pad-mobile">
          <h2
            className="general"
            style={{ marginBottom: "2rem", fontSize: 48 }}
          >
            La nostra <span>struttura</span>
          </h2>
          <img src="/files/img/utilitalia-struttura-2025.png" alt="La nostra struttura - Utilitalia" width="" heigh="" style={{width:'100%', borderRadius:0, height: 'auto'}} />
            <p style={{textAlign:'center',marginTop:40,fontSize:20}}>
                <a target="_blank" href="/files/utilitalia-struttura-2025.pdf">Scarica il pdf</a>
            </p>
        </div>
      </div>
    );
  }
}
