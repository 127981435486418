import React, { Component, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import "./ChiSiamoStruttura.scss";
import IntroChiSiamo from "components/intro-chi-siamo/IntroChiSiamo";
import { ReactComponent as Water } from "icons/drop-bold.svg";
import { ReactComponent as Leaf } from "icons/leaf-bold.svg";
import { ReactComponent as Thunder } from "icons/lightning-bold.svg";
import { ReactComponent as Settings } from "icons/faders-bold.svg";
import { ReactComponent as Bag } from "icons/tote-bold.svg";
import { ReactComponent as Steam } from "icons/shield-chevron-bold.svg";
import { ReactComponent as Chat } from "icons/chats-circle-bold.svg";
import { ReactComponent as Target } from "icons/target-bold.svg";
import Placeholders from "components/placeholders/Placeholders";
import { Link } from "react-router-dom";

const Struttura = ({struttura,className})=> {
    const [active,setActive] = useState(false);
    const style = {paddingBottom:0,backgroundColor:active ? 'white' :'transparent'};
    if( className.indexOf('--last') !== -1 ) {
        style.gridColumnStart = 2;
        style.gridColumnEnd = 3;
    }
    return (
        <div className={className} style={style}>
        <a href="#" onClick={e => {e.preventDefault();setActive(!active)}}>
        <div className="heading" style={{marginBottom:0, backgroundColor: className.indexOf('--last') !== -1 ? 'rgb(136, 136, 136)' : null}}>
          {
            struttura.values.find(
              (st) => st.code === "SETTORE"
            ).value
          }
        </div>
      </a>
      { struttura?.values.find(
            (st) => st.code === "STAFF"
          ) && <>{active && <p
        style={{padding: '3rem 2rem',backgroundColor:'white'}}
        className="staff"
        dangerouslySetInnerHTML={{
          __html: struttura?.values.find(
            (st) => st.code === "STAFF"
          ).value,
        }}
      />}</> }
      { !struttura?.values.find(
            (st) => st.code === "STAFF"
          ) && <>{active && <div
        style={{padding: '3rem 2rem',backgroundColor:'white'}}
        className="staff"
        
      ><h2>
                      {
                        struttura?.values.find(
                          (st) => st.code === "NOME"
                        ).value
                      }
                    </h2>
                    <h3>
                      {
                        struttura?.values.find(
                          (st) => st.code === "CARICA"
                        ).value
                      }
                    </h3></div>}</> }

        </div>
    )
}

export default class ChiSiamoNostraStrutturaOld extends Component {
  state = {
    struttura: [],
    loading: true,
    error: false,
  };

  async componentDidMount() {
    try {
      let struttura = await axios.post(
        "https://api.utilitalia.it/DMS/Documents/List",
        {
          documentClasses: ["STRUTTURA"],
          heading: "\\CHI_SIAMO",
          folder: "STRUTTURA",
          documentValueOrders: [
            {
              code: "LIVELLO",
              type: "3",
              direction: "asc",
            },
            {
              code: "POSIZIONE",
              type: "3",
              direction: "asc",
            },
          ],
        }
      );

      struttura = struttura?.data?.data?.documents;

      struttura = struttura?.map(async (element) => {
        if (element?.values?.find((value) => value.code === "FOTO")) {
          let foto = await axios.post(
            "https://api.utilitalia.it/DMS/Files/File",

            {
              fileId: element?.values?.find((value) => value.code === "FOTO")
                ?.value?.id,
            }
          );
          foto = "data:image/jpeg;base64," + foto?.data?.data?.data;
          return { ...element, foto };
        } else {
          return { ...element };
        }
      });
      struttura = await Promise.all(struttura);
      this.setState({
        struttura: struttura,
      });
    } catch (err) {
      console.log("Error CDM ChiSiamoStruttura", err);
    }
    this.setState({ loading: false });
  }

  render() {
    const path = window.location.href;
    const onlyGeneralDirection = path.includes("direzione-generale");
    //console.log("STRUTTURA", this.state.struttura);
    return (
      <div id="chi-siamo-struttura">
        <Helmet>
          <title>Utilitalia - La Nostra Struttura - Versione HTML</title>
          <meta
            name="description"
            content="Visita il sito e scopri tutti i membri che fanno parte del team di Utilitalia. Versione HTML" 
          />
          <meta name="robots" content="noindex, nofollow"/>
        </Helmet>
        <IntroChiSiamo section="Struttura" />
        <div className="container pad-mobile">
          <h2
            className="general"
            style={{ marginBottom: "2rem", fontSize: 48 }}
          >
            La nostra <span>struttura</span>
          </h2>
          {this.state.loading ? (
            <Placeholders number={2} type="new" />
          ) : this.state.error ||
            !this.state.struttura ||
            this.state.struttura.length === 0 ? (
            <div>Error loading data</div>
          ) : (
            <>
              <div className="direzione-generale">
                <Link to="/direzione-generale/home">
                  <div className="heading">
                    {
                      this.state.struttura[0]?.values.find(
                        (st) => st.code === "SETTORE"
                      ).value
                    }
                  </div>
                </Link>
                <div className="ceo">
                  <img src={this.state.struttura[0]?.foto} alt="" />
                  <div className="right">
                    <h2>
                      {
                        this.state.struttura[0]?.values.find(
                          (st) => st.code === "NOME"
                        ).value
                      }
                    </h2>
                    <h3>
                      {
                        this.state.struttura[0]?.values.find(
                          (st) => st.code === "CARICA"
                        ).value
                      }
                    </h3>
                  </div>
                </div>
                <p
                  className="staff"
                  dangerouslySetInnerHTML={{
                    __html: this.state.struttura[0]?.values.find(
                      (st) => st.code === "STAFF"
                    ).value,
                  }}
                />
              </div>
              <div className="generale">
                <Struttura className="amministrazione" struttura={this.state.struttura[8]} />
                  {/*<Link to="/direzione-generale/amministrazione/home">
                    <div className="heading">
                      {
                        this.state.struttura[8]?.values.find(
                          (st) => st.code === "SETTORE"
                        ).value
                      }
                    </div>
                  </Link>
                  <p
                    className="staff"
                    dangerouslySetInnerHTML={{
                      __html: this.state.struttura[8]?.values.find(
                        (st) => st.code === "STAFF"
                      ).value,
                    }}
                  />*/}
                <Struttura className="organizzazione" struttura={this.state.struttura[9]} />
                  {/*<Link to="/direzione-generale/organizzazione-e-gestione-interna/home">
                    <div className="heading">
                      {
                        this.state.struttura[9]?.values.find(
                          (st) => st.code === "SETTORE"
                        ).value
                      }
                    </div>
                  </Link>
                  <p
                    className="staff"
                    dangerouslySetInnerHTML={{
                      __html: this.state.struttura[9]?.values.find(
                        (st) => st.code === "STAFF"
                      ).value,
                    }}
                  />*/}
                <Struttura className="organizzazione" struttura={this.state.struttura[11]} />
                {/*<Struttura className="organizzazione organizzazione--last" struttura={this.state.struttura[12]} />*/}
                  
                  {/*<Link to="/direzione-generale/organizzazione-e-gestione-interna/home">
                    <div className="heading">
                      {
                        this.state.struttura[10]?.values.find(
                          (st) => st.code === "SETTORE"
                        ).value
                      }
                    </div>
                  </Link>
                  <p
                    className="staff"
                    dangerouslySetInnerHTML={{
                      __html: this.state.struttura[10]?.values.find(
                        (st) => st.code === "STAFF"
                      ).value,
                    }}
                  />*/}
              </div>
              {!onlyGeneralDirection && (
                <>
                  <div className="settori">
                    <div className="acqua">
                      <Link to="/acqua/home">
                        <div className="heading">
                          <Water />
                          <span>
                            <b>
                              {
                                this.state.struttura[1]?.values.find(
                                  (st) => st.code === "SETTORE"
                                ).value
                              }
                            </b>
                          </span>
                        </div>
                      </Link>
                      <div className="ceo">
                        <img src={this.state.struttura[1]?.foto} alt="" />
                        <div className="right">
                          <h2>
                            {
                              this.state.struttura[1]?.values.find(
                                (st) => st.code === "NOME"
                              ).value
                            }
                          </h2>
                          <h3>
                            {
                              this.state.struttura[1]?.values.find(
                                (st) => st.code === "CARICA"
                              ).value
                            }
                          </h3>
                        </div>
                      </div>
                      <p
                        className="staff"
                        dangerouslySetInnerHTML={{
                          __html: this.state.struttura[1]?.values.find(
                            (st) => st.code === "STAFF"
                          ).value,
                        }}
                      />
                    </div>
                    <div className="ambiente">
                      <Link to="/ambiente/home">
                        <div className="heading">
                          <Leaf />
                          <span>
                            <b>
                              {
                                this.state.struttura[2]?.values.find(
                                  (st) => st.code === "SETTORE"
                                ).value
                              }
                            </b>
                          </span>
                        </div>
                      </Link>
                      <div className="ceo">
                        <img src={this.state.struttura[2]?.foto} alt="" />
                        <div className="right">
                          <h2>
                            {
                              this.state.struttura[2]?.values.find(
                                (st) => st.code === "NOME"
                              ).value
                            }
                          </h2>
                          <h3>
                            {
                              this.state.struttura[2]?.values.find(
                                (st) => st.code === "CARICA"
                              ).value
                            }
                          </h3>
                        </div>
                      </div>
                      <p
                        className="staff"
                        dangerouslySetInnerHTML={{
                          __html: this.state.struttura[2]?.values.find(
                            (st) => st.code === "STAFF"
                          ).value,
                        }}
                      />
                    </div>
                    <div className="energia">
                      <Link to="/energia/home">
                        <div className="heading">
                          <Thunder />
                          <span>
                            <b>
                              {
                                this.state.struttura[3]?.values.find(
                                  (st) => st.code === "SETTORE"
                                ).value
                              }
                            </b>
                          </span>
                        </div>
                      </Link>
                      <div className="ceo">
                        <img src={this.state.struttura[3]?.foto} alt="" />
                        <div className="right">
                          <h2>
                            {
                              this.state.struttura[3]?.values.find(
                                (st) => st.code === "NOME"
                              ).value
                            }
                          </h2>
                          <h3>
                            {
                              this.state.struttura[3]?.values.find(
                                (st) => st.code === "CARICA"
                              ).value
                            }
                          </h3>
                        </div>
                      </div>
                      <p
                        className="staff"
                        dangerouslySetInnerHTML={{
                          __html: this.state.struttura[3]?.values.find(
                            (st) => st.code === "STAFF"
                          ).value,
                        }}
                      />
                    </div>
                  </div>
                  <div className="aree">
                    <div className="lavoro">
                      <Link to="/area-lavoro-relazioni-industriali/home">
                        <div className="heading">
                          <Bag />
                          <span>
                            <b>
                              {
                                this.state.struttura[4]?.values.find(
                                  (st) => st.code === "SETTORE"
                                ).value
                              }
                            </b>
                          </span>
                        </div>
                      </Link>
                      <div className="ceo">
                        <img src={this.state.struttura[4]?.foto} alt="" />
                        <div className="right">
                          <h2>
                            {
                              this.state.struttura[4]?.values.find(
                                (st) => st.code === "NOME"
                              ).value
                            }
                          </h2>
                          <h3>
                            {
                              this.state.struttura[4]?.values.find(
                                (st) => st.code === "CARICA"
                              ).value
                            }
                          </h3>
                        </div>
                      </div>
                      <p
                        className="staff"
                        dangerouslySetInnerHTML={{
                          __html: this.state.struttura[4]?.values.find(
                            (st) => st.code === "STAFF"
                          ).value,
                        }}
                      />
                    </div>
                    <div className="giuridico">
                      <Link to="/area-giuridico-legislativa-fiscale/home">
                        <div className="heading">
                          <Steam />
                          <span>
                            <b>
                              {
                                this.state.struttura[5]?.values.find(
                                  (st) => st.code === "SETTORE"
                                ).value
                              }
                            </b>
                          </span>
                        </div>
                      </Link>
                      <div className="ceo">
                        <img src={this.state.struttura[5]?.foto} alt="" />
                        <div className="right">
                          <h2>
                            {
                              this.state.struttura[5]?.values.find(
                                (st) => st.code === "NOME"
                              ).value
                            }
                          </h2>
                          <h3>
                            {
                              this.state.struttura[5]?.values.find(
                                (st) => st.code === "CARICA"
                              ).value
                            }
                          </h3>
                        </div>
                      </div>
                      <p
                        className="staff"
                        dangerouslySetInnerHTML={{
                          __html: this.state.struttura[5]?.values.find(
                            (st) => st.code === "STAFF"
                          ).value,
                        }}
                      />
                    </div>
                    <div className="affari">
                      <Link to="/area-affari-regolatori/home">
                        <div className="heading">
                          <Settings />
                          <span>
                            <b>
                              {
                                this.state.struttura[6]?.values.find(
                                  (st) => st.code === "SETTORE"
                                ).value
                              }
                            </b>
                          </span>
                        </div>
                      </Link>
                      <div className="ceo">
                        <img src={this.state.struttura[6]?.foto} alt="" />
                        <div className="right">
                          <h2>
                            {
                              this.state.struttura[6]?.values.find(
                                (st) => st.code === "NOME"
                              ).value
                            }
                          </h2>
                          <h3>
                            {
                              this.state.struttura[6]?.values.find(
                                (st) => st.code === "CARICA"
                              ).value
                            }
                          </h3>
                        </div>
                      </div>
                      <p
                        className="staff"
                        dangerouslySetInnerHTML={{
                          __html: this.state.struttura[6]?.values.find(
                            (st) => st.code === "STAFF"
                          ).value,
                        }}
                      />
                    </div>
                    <div className="strategie">
                      <Link to="/area-strategie-pianificazione/home">
                        <div className="heading">
                          <Target />
                          <span>
                            <b>
                              {
                                this.state.struttura[10]?.values.find(
                                  (st) => st.code === "SETTORE"
                                ).value
                              }
                            </b>
                          </span>
                        </div>
                      </Link>
                      <div className="ceo">
                        <img src={this.state.struttura[10]?.foto} alt="" />
                        <div className="right">
                          <h2>
                            {
                              this.state.struttura[10]?.values.find(
                                (st) => st.code === "NOME"
                              ).value
                            }
                          </h2>
                          <h3>
                            {
                              this.state.struttura[10]?.values.find(
                                (st) => st.code === "CARICA"
                              ).value
                            }
                          </h3>
                        </div>
                      </div>
                      <p
                        className="staff"
                        dangerouslySetInnerHTML={{
                          __html: this.state.struttura[10]?.values.find(
                            (st) => st.code === "STAFF"
                          ).value,
                        }}
                      />
                    </div>
                    <div className="comunicazione">
                      <Link to="/area-comunicazione/home">
                        <div className="heading">
                          <Chat />
                          <span>
                            <b>
                              {
                                this.state.struttura[7]?.values.find(
                                  (st) => st.code === "SETTORE"
                                ).value
                              }
                            </b>
                          </span>
                        </div>
                      </Link>
                      <div className="ceo">
                        <img src={this.state.struttura[7]?.foto} alt="" />
                        <div className="right">
                          <h2>
                            {
                              this.state.struttura[7]?.values.find(
                                (st) => st.code === "NOME"
                              ).value
                            }
                          </h2>
                          <h3>
                            {
                              this.state.struttura[7]?.values.find(
                                (st) => st.code === "CARICA"
                              ).value
                            }
                          </h3>
                        </div>
                      </div>
                      <p
                        className="staff"
                        dangerouslySetInnerHTML={{
                          __html: this.state.struttura[7]?.values.find(
                            (st) => st.code === "STAFF"
                          ).value,
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
              
            </>
          )}
        </div>
      </div>
    );
  }
}
