import "./AreaList.scss";
import { ReactComponent as Settings } from "icons/faders-bold.svg";
import { ReactComponent as Bag } from "icons/tote-bold.svg";
import { ReactComponent as Steam } from "icons/shield-chevron-bold.svg";
import { ReactComponent as Chat } from "icons/chats-circle-bold.svg";
import { ReactComponent as Book } from "icons/book-bold.svg";
import { ReactComponent as Target } from "icons/target-bold.svg";
import { Link } from "react-router-dom";
import { useState, Fragment } from 'react';

const areas = [
  {
    empty: true,
  },
  {
    empty: false,
    title: "Area affari regolatori",
    icon: <Settings />,
    color: 'var(--affari)',
    to: "/area-affari-regolatori/home",
    html: "<header><img src='/files/img/Francesca_Mazzarella.jpeg' alt='Francesca Mazzarella' width='60' height='60' /><div><h3>Direttore ad interim</h3><p>Francesca Mazzarella</p></div></header><h3>Collaboratori</h3><p>Fabrizio Falconi<br/>Samanta Meli<br/>Carmen Monaco<br/>Gaia Rodriquez</p>"
  },
  {
    empty: false,
    title: "Area lavoro e relazioni industriali",
    color: 'var(--lavoro)',
    icon: <Bag />,
    to: "/area-lavoro-relazioni-industriali/home",
    html: "<header><img src='/files/img/Paola_Giuliani.jpeg' alt='Paola Giuliani' width='60' height='60' /><div><h3>Direttore</h3><p>Paola Giuliani</p></div></header><h3>Collaboratori</h3><p>Ilenia Casanova<br />Francesco Gioscia<br/>Lorenzo Lama<br/>Martino Moioli<br/>Simona Saccomanno</p>"
  },
  {
    empty: false,
    title: "Area giuridico legislativa e fiscale",
    color: 'var(--giuridico)',
    icon: <Steam />,
    to: "/area-giuridico-legislativa-fiscale/home",
    html: "<header><img src='/files/img/Giulia_Chieffo.jpeg' alt='Giulia Chieffo' width='60' height='60' /><div><h3>Direttore</h3><p>Giulia Chieffo</p></div></header><h3>Collaboratori</h3><p>Pina Russo<br/>Margherita Tria</p>"
  },
  {
    empty: false,
    title: "Area Strategie e Pianificazione di settore",
    color: 'var(--strategie)',
    icon: <Target />,
    to: "/area-strategie-pianificazione/home",
    html: "<header><img src='/files/img/Francesca_Mazzarella.jpeg' alt='Francesca Mazzarella' width='60' height='60' /><div><h3>Direttore</h3><p>Francesca Mazzarella</p></div></header><h3>Collaboratori</h3><p>Andrea Di Piazza<br/>Mattia Galipò<br/>Valeria Zingarelli</p>"
  },
  {
    empty: true,
  },
];

const subareas = [
    {
    title: "Affari istituzionali ed europei",
    color: 'var(--amministrazione)',
    //icon: <Book />,
    //to: "/direzione-generale/home",
    html: "<h3>Responsabile</h3><p>Giulia Chieffo</p><h3>Collaboratori</h3><p>Luigi Joseph Del Giacco<br/>Damiano Ruffini</p>"
  
  },
  {
    title: "Amministrazione",
    color: 'var(--affari)',
    //icon: <Book />,
    to: "/direzione-generale/amministrazione/home",
     html: "<h3>Responsabile</h3><p>Antonella Balbi</p><h3>Collaboratori</h3><p>Lorenzo Di Marco<br/>Paola Mazzone<br/>Patrizia Montanaro</p>"
  },
  {
    title: "Direzione e Organizzazione interna",
    color: 'var(--lavoro)',
    //icon: <Book />,
    to: "/direzione-generale/home",
     html: "<h3>Responsabile</h3><p>Giovanna Nesticò</p><h3>Coordinatore Presidenza, Direzione</h3><p>Fiorella Lettieri</p><h3>Coordinatore Organi Statutari</h3><p>Francesca Coppola</p><h3>Collaboratori</h3><p>Paolo Carrubba<br/>Andrea De Angelis<br/>Elisa Pecoraro</p><h3>Staff di segreteria (Aree/Settori)</h3><p>Coordinatore: Alessandra Macrina<br/>Marina Bellantoni<br/>Simona Cirillo</p>"
  },
  {
    title: "Eventi e Convegni",
    color: 'var(--giuridico)',
    //icon: <Book />,
    //to: "/direzione-generale/home",
    to: "/area-comunicazione/home",
     html: "<h3>Responsabile</h3><p>Gloria Giombini</p><h3>Collaboratori</h3><p>Mauro Antonioli<br/>Riccardo Perri</p>"
  },
  {
    title: "Media Relations",
    color: 'var(--strategie)',
    //icon: <Chat />,
    to: "/area-comunicazione/home",
     html: "<h3>Responsabile</h3><p>Domenico Zaccaria</p><h3>Collaboratori</h3><p>Mauro Antonioli<br/>Dario Tersigni</p>"
  },
  {
    title: "Servizio funerario",
    color: '#0066a8',
    to: 'https://www.sefit.org',
    //icon: <Chat />,
    //to: "/area-comunicazione/home",
     html: "<h3>Responsabile</h3><p>Valeria Leotta</p>"
  },
  {
    title: "Servizi IT e multimediali",
    color: 'var(--comunicazione)',
    //icon: <Chat />,
     html: "<h3>Responsabile</h3><p>Tiziano Gallo</p><h3>Collaboratori</h3><p>Mauro Antonioli<br/>Paolo Carrubba</p>"
  },
];

const AreaListNew = () => {
    const [current, setCurrent ] = useState(null);
    const [subCurrent, setSubCurrent] = useState(null);
    return (
  <div className="list-categories-component">
    {window.location.pathname !== "/" && (
      <h6 className="pad-mobile">Servizi e Aree Correlate</h6>
    )}

    <div className="list">
      {areas.map((element, i) => (
        <Fragment key={i}>
        { element?.empty ? (<div className="category category--empty" />) : (<div onClick={e => setCurrent( state => state === i ? null : i)} className={`category${ current === i ? ' active' :''}`}>
                  {element.icon}
                  <h4
                    style={{ fontSize: 18 }}
                    dangerouslySetInnerHTML={{ __html: element.title }}
                  />
                </div>)}
        </Fragment>
      ))}
    </div>
    { current !== null && <div className="expanded"><h2 style={{backgroundColor:areas[current].color}}><span dangerouslySetInnerHTML={{ __html: areas[current].title }} />
        <Link to={areas[current].to}>Approfondisci</Link>
        </h2>
        <div>
        <p dangerouslySetInnerHTML={{__html:areas[current].html}} />
    </div></div>}
    <div className="list list--sub"  style={{marginTop: 20}}>
      {subareas.map((element, i) => (
        <Fragment key={i + 10}>
        { element?.empty ? (<div className="category" />) : (<div onClick={e => setSubCurrent( state => state === i ? null : i)} className={`category${ subCurrent === i ? ' active' :''}`}>
                  {element.icon}
                  <h4
                    dangerouslySetInnerHTML={{ __html: element.title }}
                  />
                </div>)}
        </Fragment>
      ))}
    </div>
    { subCurrent !== null && <div className="expanded expanded--sub">
        <h2 style={{backgroundColor:subareas[subCurrent].color}}><span dangerouslySetInnerHTML={{ __html: subareas[subCurrent].title }} />
        { subareas[subCurrent].to && subareas[subCurrent].to.indexOf('http') === -1 ? (<Link to={subareas[subCurrent].to}>Approfondisci</Link>) : (<span>Approfondisci</span>)}
        { subareas[subCurrent].to && subareas[subCurrent].to.indexOf('http') !== -1 && <a target="_blank" href={subareas[subCurrent].to}>Approfondisci</a> }
        </h2>
        <div>
        <p dangerouslySetInnerHTML={{__html:subareas[subCurrent].html}} />
    </div></div>}
  </div>
)};

export default AreaListNew;
