import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { ReactComponent as Download } from "icons/file-arrow-down-bold.svg";
import Spinner from "components/spinner/Spinner";
import axios from "axios";
import { Link } from "react-router-dom";

export default class attachmentButton extends Component {
  state = { loading: false };

  fetchPdf = async (id) => {
    const sessionId = sessionStorage.getItem("sessionId");
    const headers = {
      headers: { "Session-Id": sessionId },
    };
    try {
      let file = await axios.post(
        "https://api.utilitalia.it/DMS/Files/File",
        {
          fileId: id,
        },
        headers
      );
      
      const status = file?.data?.status;
      file = file.data && file.data.data;
      if (file) {
        this.setState({
          pdf: file && file.data,
          pdfName: file && file.file && file.file.name,
        });
      } else if (isMobile) {
        if(status === 'FORBIDDEN') {
            this.setState({ loading: false, showforbidden: true });
        } else {
            this.props.history.push("/login");
        }  
      } else {
        this.props.history.push("/login");
      }
    } catch (err) {
      this.setState({ error: true, showforbidden: false  });
    }
  };

  getFileType(pdfName) {
    const type = pdfName.split(".");
    switch (type[type.length - 1]) {
      case "pdf":
        return "application/pdf";
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case "xls":
        return "application/vnd.ms-excel";
      case "xlsx":
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      case "pptx":
        return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      default:
        return "application/pdf";
    }
  }

  // handlePdf = async (id) => {
  //   window.location.href = `/pdf/${id}`;
  // };

  render() {
    return (
      <div
        style={{ marginLeft: 8, cursor: "pointer", marginBottom: 8 }}
        className="btn"
      >
        {this.state.loading && !this.state.showforbidden ? (
          <Spinner btnSpinner={true} />
        ) : (
          // <div
          //   onClick={() => this.handlePdf(this.props.id)}
          //   style={{ display: "flex", alignItems: "center" }}
          // >
          //   <Download width={16} height={16} style={{ marginRight: 4 }} />
          //   {this.props.text}
          // </div>
          <>{ this.state.showforbidden ? <p>Non si dispone di privilegi sufficienti per accedere al contenuto richiesto</p> : <Link
            to={`/pdf/${this.props.id}`}
            style={{ display: "flex", alignItems: "center", color: "white" }}
          >
            <Download width={16} height={16} style={{ marginRight: 4 }} />
            {this.props.text}
          </Link>}</>
        )}

      </div>
    );
  }
}

//
