import React, { Component, useState } from "react";
import axios from "axios";
import Spinner from "components/spinner/Spinner";
import "./PdfPages.scss";
import { isMobile } from "react-device-detect";
import { CaretRight, CaretLeft } from "phosphor-react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default class PdfPages extends Component {
  state = {
    loading: true,
  };

  async componentDidMount() {
    this.setState({ loading: false,showforbidden : false });
    await this.fetchPdf(this.props.match.params.id);
  }

  fetchPdf = async (fileId) => {
    // se crawler non carico (timeout error)
    if (navigator.userAgent !== "ReactSnap") {
      try {
        const sessionId = sessionStorage.getItem("sessionId");
        const urlSession = window.location.search.substring(1);
        console.log( urlSession || sessionId )
        const headers = {
          headers: { "Session-Id": urlSession || sessionId },
        };
        let file = await axios.post(
          "https://api.utilitalia.it/DMS/Files/File",
          {
            fileId,
          },
          headers
        );
        const status = file?.data?.status;
        file = file?.data?.data;
        console.log(file)
        if (file) {
          this.setState({ pdf: file?.data, pdfName: file?.file?.name });
        } else {
            if( status === 'FORBIDDEN') {
                 this.setState({ loading: false,showforbidden : true });
            } else {
                this.props.history.push({ pathname: "/login", state: true });
            }
          // se non è loggato
          
        }
      } catch (err) {
        console.log("Error fetching pdf", err);
      }
    }
  };

  getFileType(pdfName) {
    const type = pdfName.split(".");
    switch (type[type.length - 1]) {
      case "pdf":
        return "application/pdf";

      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case "xls":
        return "application/vnd.ms-excel";
      case "xlsx":
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      case "pptx":
        return "application/vnd.openxmlformats-officedocument.presentationml.presentation";

      default:
        return "application/pdf";
    }
  }

  b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  render() {
    let blobUrl;
    if (this.state.pdf && !this.state.loading) {
      const blob = this.b64toBlob(
        this.state.pdf,
        this.getFileType(this.state.pdfName)
      );
      blobUrl = URL.createObjectURL(blob);
      if (this.getFileType(this.state.pdfName) !== "application/pdf") {
        if (isMobile) {
          const linkSource = blobUrl;
          const downloadLink = document.createElement("a");
          const fileName = this.state.pdfName;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          setTimeout(() => {
            window.close();
          }, 2000);
        }
      }
    }
    console.log( this.state )
    return (
      <div>
        {this.state.loading && !this.state.showforbidden ? (
          <div
            id="pdf-loader"
            style={{
              height: window.innerHeight - 88 - 25 * 16,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        ) : isMobile ? (
          <>
            {this.getFileType(this.state.pdfName) !== "application/pdf" ? (
              <div
                style={{
                  padding: "120px 5%",
                  textAlign: "center",
                }}
              >
                <p>Il download inizierà in pochi secondi</p>
                <p>
                  <span
                    onClick={() => {
                      const linkSource = blobUrl;
                      const downloadLink = document.createElement("a");
                      const fileName = this.state.pdfName;
                      downloadLink.href = linkSource;
                      downloadLink.download = fileName;
                      downloadLink.click();
                    }}
                    style={{ textDecoration: "underline" }}
                  >
                    Clicca qui
                  </span>{" "}
                  se non parte in automatico
                </p>
              </div>
            ) : (
              <PdfViewer url={blobUrl} />
            )}
          </>
        ) : (
          <>{ this.state.showforbidden ? (<div
                style={{
                  padding: "120px 5%",
                  textAlign: "center",
                }}
              ><p>Non si dispone di privilegi sufficienti per accedere al contenuto richiesto</p></div>) : (<iframe
            id="pdf-iframe"
            style={{ width: "100%", height: window.innerHeight - 88 }}
            title="pdf-viewer"
            src={blobUrl}
          ></iframe>) }</>
        )}
      </div>
    );
  }
}

const PdfViewer = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const height =
    document.getElementById("pdfPage") &&
    document.getElementById("pdfPage").clientHeight;
  return (
    <>
      <div
        id={"pdfPage"}
        style={{
          minHeight: height,
          paddingBottom: numPages > 1 ? 0 : 16,
          marginTop: 16,
        }}
      >
        <Document
          file={{ url: props.url }}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page width={window.innerWidth} pageNumber={pageNumber} />
        </Document>
      </div>
      {numPages > 1 && (
        <div className={"pagination"}>
          {pageNumber > 1 && (
            <CaretLeft
              size={16}
              onClick={() => setPageNumber(pageNumber - 1)}
            />
          )}
          <p style={{ paddingRight: 8, paddingLeft: 8 }}>
            Pagina {pageNumber} di {numPages}
          </p>
          {pageNumber < numPages && (
            <CaretRight
              size={16}
              onClick={() => setPageNumber(pageNumber + 1)}
            />
          )}
        </div>
      )}
    </>
  );
};
